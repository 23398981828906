import type { AgentsSelection } from '@dx-ui/framework-conductrics';
import { shouldRenderExperiment } from '@dx-ui/framework-conductrics';
import type { TDrawerItem } from '../types';

export function filterMegaNavExperiments(
  megaNavLinks: TDrawerItem[] | undefined,
  experimentationAgents: AgentsSelection | undefined = {}
) {
  if (!megaNavLinks) {
    return;
  }

  return megaNavLinks
    ?.map((item) => {
      if (
        item.link?.experimentationConfiguration &&
        !shouldRenderExperiment(experimentationAgents, item.link?.experimentationConfiguration)
      ) {
        return null;
      }

      const subMenu =
        item.subMenu?.filter((secondary) => {
          if (!secondary.link?.experimentationConfiguration) {
            return true;
          }

          return shouldRenderExperiment(
            experimentationAgents,
            secondary.link?.experimentationConfiguration
          );
        }) ?? [];

      const drawerItem: TDrawerItem = {
        ...item,
      };

      if (subMenu.length) {
        drawerItem.subMenu = subMenu;
      }

      return drawerItem;
    })
    .filter((item) => !!item);
}
