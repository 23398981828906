import { getCookie, setCookie } from '@dx-ui/utilities-cookies';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { addDays, startOfToday } from 'date-fns';
import type { IncomingMessage } from 'http';
import { isServer } from '@tanstack/react-query';
import type { GetServerSidePropsContext } from 'next';
import type { DXError } from '@dx-ui/types-graphql';
import { ServerAuthError, GraphError } from '@dx-ui/types-graphql';

export function causedByUnauthorized(error?: DXError) {
  if (error) {
    if (error instanceof ServerAuthError) {
      return error.statusCode === 401;
    }
    if (error instanceof GraphError) {
      return (
        error.graphQLErrors?.some(
          (error) => error?.message === 'Unauthorized' || error?.code === 401
        ) || false
      );
    }
  }
  return false;
}

export function causedByForbidden(error?: DXError) {
  if (error) {
    if (error instanceof ServerAuthError) {
      return error.statusCode === 403;
    }
    if (error instanceof GraphError) {
      return (
        error.graphQLErrors?.some(
          (error) => error?.message === 'Forbidden' || error?.code === 403
        ) || false
      );
    }
  }
  return false;
}

export function causedByNotFound(error?: DXError) {
  if (error) {
    if (error instanceof ServerAuthError) {
      return error.statusCode === 404;
    }
    if (error instanceof GraphError) {
      return (
        error.graphQLErrors?.some(
          (error) => error?.message === 'Not Found' || error?.code === 404
        ) || false
      );
    }
  }
  return false;
}

export function getVisitorId() {
  if (!isBrowser) {
    return null;
  }
  const visitorId: string = getCookie('visitorId');
  return visitorId || setVisitorId();
}

export function setVisitorId() {
  if (isBrowser) {
    const uuid = crypto.randomUUID();
    setCookie('visitorId', uuid, {
      expires: addDays(startOfToday(), 90),
      domain: window.location.hostname,
    });
    return uuid;
  }
  return null;
}

export function generateReferrer(origin: string, language: string, request?: IncomingMessage) {
  try {
    const pathname = isServer ? `/${language}${request?.url}` : window.location.pathname;
    const url = new URL(pathname, origin);
    return `${url.origin}${url.pathname}`;
  } catch {
    return undefined;
  }
}

export function getSafeLanguage(language: string) {
  const zhMapping: { [key: string]: string } = {
    'zh-cn': 'zh-hans',
    'zh-hk': 'zh-hant',
  };
  const mappedLang = zhMapping[language] || language;
  const splitLanguage = mappedLang.split('-') as [string, string | undefined];
  return language.includes('zh-') ? splitLanguage.slice(0, 2).join('-') : splitLanguage[0];
}

export function getServerSideSafeLanguage(context: GetServerSidePropsContext) {
  const queryContentLocale = Array.isArray(context.query.content)
    ? context.query.content[0]
    : context.query.content;
  const headerOriginalLocale =
    (context?.req.headers?.['x-originallocale'] as string)?.toLowerCase() || undefined;
  const language = queryContentLocale || headerOriginalLocale || context.locale || 'en';
  return getSafeLanguage(language);
}
