import Icon from '@dx-ui/osc-icon';
import type * as React from 'react';
import cx from 'classnames';
import { useShopFormSummaryText } from './shop-form.summary.utils';
import { useTranslation } from 'next-i18next';
import type { ShopFormRoom } from '@dx-ui/osc-rooms';
import { useMediaQuery } from 'usehooks-ts';

export type ShopFormSummaryProps = {
  arrivalDate: Date | null;
  className?: string;
  departureDate: Date | null;
  editButtonRef?: React.Ref<HTMLButtonElement>;
  isExpanded: boolean;
  isLoading?: boolean;
  language: string;
  location?: string;
  onEditClick: () => void;
  rooms: ShopFormRoom[];
  showChildAges?: boolean;
  summaryType: 'search' | 'stay';
  hideRoomSummary?: boolean;
};

export function ShopFormSummary({
  arrivalDate,
  className,
  departureDate,
  editButtonRef,
  isExpanded,
  isLoading: isLoadingProp,
  language,
  location,
  onEditClick,
  rooms,
  showChildAges,
  summaryType,
  hideRoomSummary,
}: ShopFormSummaryProps) {
  const isLargeView = useMediaQuery('(min-width: 1024px)');
  const isSmallAndMediumView = !isLargeView;
  const { t, ready } = useTranslation('osc-shop-form');
  const { dates, adaDates, kidsAgesText, occupancy, stayDuration } = useShopFormSummaryText({
    arrivalDate,
    departureDate,
    language,
    rooms,
    showChildAges,
  });
  const titleText = summaryType === 'search' ? t('yourSearchTitleCase') : t('yourStayTitleCase');
  const editText = summaryType === 'search' ? t('editSearch') : t('editStayTitleCase');
  const locationAnywhere = t('anywhere');
  const adaEditSummary =
    summaryType === 'search'
      ? t('editSearchDetails', { location, dates: adaDates, occupancy })
      : t('editStayDetails', { location: location ?? undefined, dates: adaDates, occupancy });

  const isLoading = isLoadingProp || !ready;

  const editStayButton = (
    <button
      ref={editButtonRef}
      className="btn-primary-link"
      type="button"
      aria-expanded={isExpanded}
      aria-label={adaEditSummary}
      onClick={onEditClick}
      data-testid="search-edit-button"
    >
      {editText}
    </button>
  );

  return (
    <div
      className={cx(
        'flex w-full flex-wrap justify-between gap-2 lg:flex-nowrap lg:items-center lg:justify-center lg:gap-3',
        className
      )}
      data-testid="search-summary"
    >
      <div className="font-bold lg:shrink-0">
        {isLoading ? <SkeletonLoader className="h-6 w-16" /> : titleText}
      </div>
      {isLoading ? (
        <SkeletonLoader className="h-6 w-16 lg:hidden" />
      ) : isSmallAndMediumView ? (
        editStayButton
      ) : null}
      <div className="lg:divide-border lg:border-border flex w-full flex-col gap-2 lg:w-auto lg:flex-row lg:gap-3 lg:divide-x lg:border-e lg:pe-3 rtl:lg:divide-x-reverse lg:[&>*:not(:first-child)]:ps-3 lg:[&>*]:py-2">
        {isLoading ? (
          <SkeletonLoader className="h-6 w-20 lg:self-center" />
        ) : (
          <div className="flex w-full gap-1 lg:w-auto lg:min-w-0">
            <Icon name="hotel-location" variant="solid" className="size-6" />
            <span
              className="text-base font-normal lg:truncate"
              title={location || locationAnywhere}
            >
              {location || locationAnywhere}
            </span>
          </div>
        )}
        <div className="flex w-full gap-1 lg:w-auto lg:shrink-0" data-testid="search-summary-dates">
          <Icon name="calendar" variant="solid" className="size-6" />
          {isLoading ? (
            <SkeletonLoader className="h-6 w-64" />
          ) : (
            <>
              <span aria-hidden={true}>
                {dates}
                {dates ? ' ' : null}
                <span
                  className={cx('inline-block whitespace-nowrap text-base font-normal', {
                    'text-text-alt': dates,
                  })}
                  data-testid="search-summary-nights"
                >
                  {dates ? `(${stayDuration})` : stayDuration}
                </span>
              </span>
              <span className="sr-only">
                {adaDates} {stayDuration}
              </span>
            </>
          )}
        </div>
        {hideRoomSummary ? null : (
          <div className="flex w-full gap-1 lg:w-auto lg:min-w-0">
            <Icon name="users" className="size-6" />
            {isLoading ? (
              <SkeletonLoader className="h-6 w-28" />
            ) : (
              <span
                className="lg:truncate"
                title={`${occupancy}${kidsAgesText ? kidsAgesText : ''}`}
              >
                {occupancy}
                {kidsAgesText ? <span className="text-text-alt"> {kidsAgesText}</span> : null}
              </span>
            )}
          </div>
        )}
      </div>
      {isLoading ? (
        <SkeletonLoader className="hidden h-6 w-16 lg:block" />
      ) : isLargeView ? (
        editStayButton
      ) : null}
    </div>
  );
}

function SkeletonLoader({
  className,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) {
  return (
    <span
      className={cx('bg-bg-alt inline-block animate-pulse align-middle', className)}
      {...rest}
    />
  );
}
