import * as React from 'react';
import type { Rule } from '@dx-ui/utilities-url-config';
import { RuleEngine, getUrlConfig } from '@dx-ui/utilities-url-config';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useTranslationsConfig } from './hooks/useFeatureConfigs';
import { getOneLinkProps } from '@dx-ui/framework-i18n';

type SpeedbumpAttributes = {
  isEnabled: boolean;
  isVisible: boolean;
  onShow: (e: React.SyntheticEvent<HTMLAnchorElement>) => boolean;
  onHide: () => void;
  onContinue: () => void;
};

type UseSpeedbumpProps = {
  /** Used to determine whether to show or hide the speedbump.
   * Also used to determine the destination language code to use in the target url
   */
  language: string;
  appName: string;
};

/**
 *  Can be used to act as an anchor event target when using the useSpeedbump hook without a link
 */
export function makeAnchorEventTarget(absoluteUrl: string) {
  const url = new URL(absoluteUrl);
  return {
    preventDefault: () => undefined,
    stopPropagation: () => undefined,
    currentTarget: {
      href: absoluteUrl,
      origin: url.origin,
      pathname: url.pathname,
    } as unknown as HTMLAnchorElement,
    target: {
      href: absoluteUrl,
    } as unknown as HTMLAnchorElement,
  } as unknown as React.ChangeEvent<HTMLAnchorElement>;
}

export const DocUseSpeedbump: React.FC<React.PropsWithChildren<UseSpeedbumpProps>> = () => null;

function transformUrl({
  href,
  pathname,
  language,
}: {
  href: string;
  pathname: string;
  language: string;
}) {
  if (pathname.startsWith('/zh/') && (language === 'zh-hans' || language === 'zh-hant')) {
    return href.replace('/zh/', `/${language}/`);
  }
  return href;
}
/**
 * This hook provides the ability to replace in language urls with english only urls.
 * It only works for HTMLAnchorElement links and will not work with other elements as
 * it will look for a `href` attribute on the target or parent element.
 *
 * It will replace the in-language code with `en` and open the location on the window.
 */
const useSpeedbump = (
  language: string,
  appName?: string,
  bypassOneLink?: boolean
): SpeedbumpAttributes => {
  const DEFAULT_LANGUAGE = 'en';
  const { oneLinkConfig, translateRules } = useTranslationsConfig();
  const url = isBrowser ? window.location.href : '';
  const oneLinkProps = getOneLinkProps(language, appName || '', url, oneLinkConfig);
  const translateRuleEngine = React.useMemo(
    () => (translateRules ? new RuleEngine(translateRules as Rule) : null),
    [translateRules]
  );
  const [isEnabled] = React.useState(language !== DEFAULT_LANGUAGE);
  const [targetLanguage] = React.useState(language);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [eventTarget, setEventTarget] = React.useState<HTMLAnchorElement>();
  const onShow = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    const { target } = e as React.ChangeEvent<HTMLAnchorElement>;
    setEventTarget(target);
    const { href, origin, pathname } =
      e.currentTarget || (target.parentElement as HTMLAnchorElement);
    if (!translateRuleEngine) {
      return false;
    }
    const url = transformUrl({ href, pathname, language });
    if (href !== url) {
      e.currentTarget.href = url;
    }
    const urlConfig = getUrlConfig(translateRuleEngine, url, origin, language);
    const shouldShowSpeedbump = (!oneLinkProps.isOneLink || bypassOneLink) && urlConfig.speedbump;
    if (isEnabled && shouldShowSpeedbump) {
      e.preventDefault();
      e.stopPropagation();
      setIsVisible(true);
      return true;
    }
    return false;
  };
  const onHide = () => {
    setIsVisible(false);
  };
  const onContinue = () => {
    setIsVisible(false);
    if (isBrowser && eventTarget) {
      // hack, will need to revisit. Looks for current element or parent to find the href
      const href = eventTarget.href || (eventTarget.parentElement as HTMLAnchorElement).href;
      const enUri = href?.replace(`/${targetLanguage}/`, `/${DEFAULT_LANGUAGE}/`);
      enUri &&
      (eventTarget.target === '_blank' ||
        (eventTarget.parentElement as HTMLAnchorElement)?.target === '_blank')
        ? window.open(enUri, eventTarget.target)
        : (window.location.href = enUri);
    }
  };
  return {
    isEnabled,
    isVisible,
    onShow,
    onHide,
    onContinue,
  };
};

export { useSpeedbump };
export default useSpeedbump;
