import type { AgentsSelection } from '../conductrics-hooks';

/**
 * Determine if an item should be rendered based on conductrics state the item's ExperimentationConfiguration.
 */
export function shouldRenderExperiment(
  agents: AgentsSelection,
  config?: CmsExperimentationConfiguration
) {
  // Item has no config so we should always render it
  if (!config) {
    return true;
  }

  const { agentId, variation } = config;

  // Config is incomplete - we need both an ID and variation
  if (!agentId || !variation) {
    return true;
  }

  const agent = agents[agentId];

  // Agent doesn't exist or there has been an error
  if (!agent || !!agent.error) {
    return false;
  }

  // Only show the control variant if an agent has not loaded yet
  if (!agent.isLoaded) {
    return variation === 'a';
  }

  // CMS has the values in lowercase
  return agent.selection?.choice?.toLowerCase() === variation;
}
