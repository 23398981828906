/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';
import { PopupButton, PopupContent, usePopup } from '@dx-ui/osc-popup';
import { sendInteractionReward } from '../header.utilities';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { useTranslation } from 'next-i18next';
import type { LoginResponse } from '@dx-ui/osc-login';
import { LoginIFrame } from '@dx-ui/osc-login';
import { Link } from '@dx-ui/osc-link';
import cx from 'classnames';
import type { HeaderProps } from '../types';
import Icon, { type IconNames } from '@dx-ui/osc-icon';

type IconName = (typeof IconNames)[number];

type NavItemSection = 'navigation' | 'user';

type NavItemProps = Omit<React.ComponentProps<'li'>, 'className' | 'children'> &
  Pick<HeaderProps, 'theme'> & {
    isOpen?: boolean;
    children: React.ReactElement;
  };

export function NavItem({ children, theme, isOpen = false, ...listItemProps }: NavItemProps) {
  const isDark = theme === 'dark';

  const spacedChild = React.cloneElement(children, {
    className: cx(children.props.className, 'flex-1 self-stretch'),
  });

  return (
    <li
      {...listItemProps}
      className={cx('group flex h-full items-center px-1 pt-1 text-sm', {
        'nav-list-item': !theme,
        'nav-list-item-dark': isDark,
        'nav-list-item-active': isOpen && !theme,
        'nav-list-item-active-dark': isOpen && isDark,
      })}
    >
      {spacedChild}
    </li>
  );
}

export function NavItemVisual({
  label,
  icon,
  indicator,
  hasRotatedIndicator,
  theme,
  section,
}: {
  label: string;
  theme: 'light' | 'dark' | undefined;
  icon?: IconName;
  indicator?: IconName;
  section: NavItemSection;
  hasRotatedIndicator?: boolean;
}) {
  const isDark = theme === 'dark';

  return (
    <>
      <span className={cx('block min-h-5', section === 'user' ? 'nav-item-text' : null)}>
        {label}
      </span>

      {icon ? (
        <div className={cx('ps-2')}>
          <Icon
            name={icon}
            className={cx(
              isDark ? 'nav-icon-dark' : 'nav-icon',
              'group-focus-within:hidden group-hover:hidden'
            )}
            wrapper="span"
          />
          <Icon
            name={icon}
            variant="solid"
            className={cx(
              isDark ? 'nav-icon-active-dark' : 'nav-icon-active',
              'hidden group-focus-within:block group-hover:block'
            )}
            wrapper="span"
          />
        </div>
      ) : null}

      {indicator ? (
        <div>
          <Icon
            name={indicator}
            className={cx(
              hasRotatedIndicator ? 'rotate-180' : null,
              'group-focus-within:hidden group-hover:hidden'
            )}
            wrapper="span"
          />
          <Icon
            name={indicator}
            className={cx(
              hasRotatedIndicator ? 'rotate-180' : null,
              'hidden group-focus-within:block group-hover:block'
            )}
            wrapper="span"
          />
        </div>
      ) : null}
    </>
  );
}

type NavItemWithLinkProps = Omit<Link, 'className' | 'children' | 'icon'> & {
  theme: 'dark' | undefined;
  section: NavItemSection;
  label: string;
  icon?: React.ReactElement;
  namedIcon?: IconName;
  isWide?: boolean;
  experimentationConfiguration?: CmsExperimentationConfiguration;
};

//TODO: make the focus ring here take up the full box, like with the dropdowns. Also check ItemWithModal
export const NavItemWithLink: React.FC<NavItemWithLinkProps> = ({
  theme,
  namedIcon,
  section,
  label,
  isWide,
  experimentationConfiguration,
  ...rest
}) => {
  return (
    <NavItem theme={theme} data-testid="with-link">
      <Link
        underline={false}
        {...rest}
        data-conductrics-goal={experimentationConfiguration?.goal}
        data-conductrics-value={experimentationConfiguration?.value}
        className={cx('flex items-center whitespace-nowrap', isWide ? 'p-2' : 'p-1', {
          'nav-main-link': section === 'navigation',
        })}
      >
        <NavItemVisual section={section} theme={theme} label={label} icon={namedIcon} />
      </Link>
    </NavItem>
  );
};

type TNavItemWithModal = {
  /** Specify the title for the Dialog */
  title: string;
  /** callback to run after login attempt */
  onLoginAttempt: React.ComponentProps<typeof LoginIFrame>['onLoginAttempt'];
  /** Name of the button to display */
  buttonName?: string;
  /** aria-label of the button */
  buttonLabel: string;
  buttonIcon?: IconName;
  onClick?: () => void;
  theme: 'dark' | undefined;
  section: NavItemSection;
};

export const NavItemWithModal: React.FC<React.PropsWithChildren<TNavItemWithModal>> = ({
  theme,
  section,
  buttonLabel,
  buttonIcon,
  onClick,
  title,
  onLoginAttempt,
  children,
}) => {
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const open = () => {
    sendInteractionReward();
    onClick?.();
    setShowDialog(true);
  };
  const close = () => setShowDialog(false);

  const handleLogin = (response: LoginResponse) => {
    onLoginAttempt?.(response);
    if (!response.error) {
      close();
    }
  };

  const arrayChildren = React.Children.toArray(children);

  const [t] = useTranslation('osc-login');

  return (
    <>
      <NavItem theme={theme} data-testid="with-modal">
        <button
          data-testid="with-modal"
          type="button"
          className="group flex h-full items-center whitespace-nowrap px-1 text-sm"
          onClick={open}
        >
          <NavItemVisual
            label={buttonLabel ?? t('signIn')}
            icon={buttonIcon}
            theme={theme}
            section={section}
          />
        </button>
      </NavItem>
      <Dialog isOpen={showDialog} onDismiss={close} size="md" title={title}>
        {React.Children.map(arrayChildren, (child) => {
          if (React.isValidElement(child) && child.type === LoginIFrame) {
            return React.cloneElement(
              child as React.ReactElement<React.ComponentProps<typeof LoginIFrame>>,
              {
                onLoginAttempt: handleLogin,
                onClose: close,
              }
            );
          }
          return child;
        })}
      </Dialog>
    </>
  );
};

type TNavItemWithPopup = {
  section: NavItemSection;
  onClick?: () => void;
  theme: 'dark' | undefined;
  popupButtonRef?: React.RefObject<HTMLButtonElement>;
  label: string;
  icon?: IconName;
  indicator?: IconName;
  rotateOnOpen?: boolean;
  experimentationConfiguration?: CmsExperimentationConfiguration;
};

export const NavItemWithPopup: React.FC<React.PropsWithChildren<TNavItemWithPopup>> = ({
  children,
  label,
  icon,
  rotateOnOpen,
  onClick,
  theme,
  popupButtonRef,
  section,
  indicator,
  experimentationConfiguration,
}) => {
  const { isOpen } = usePopup();

  return (
    <>
      <NavItem theme={theme} isOpen={isOpen} data-testid="with-popup">
        <PopupButton
          ref={popupButtonRef}
          data-conductrics-goal={experimentationConfiguration?.goal}
          data-conductrics-value={experimentationConfiguration?.value}
          onClick={() => {
            sendInteractionReward();
            onClick?.();
          }}
          className={cx('flex items-center whitespace-nowrap p-2', {
            'nav-main-link': section === 'navigation',
          })}
        >
          <NavItemVisual
            theme={theme}
            label={label}
            section={section}
            indicator={indicator}
            icon={icon}
            hasRotatedIndicator={!isOpen && rotateOnOpen}
          />
        </PopupButton>
      </NavItem>
      <PopupContent
        className="motion-safe:animate-fadein !popup-margin !w-screen !p-0"
        popupStyles={{ left: 0 }}
      >
        {children}
      </PopupContent>
    </>
  );
};
