export type IconVariant = 'regular' | 'solid';

export const IconNames = [
  '360-tour',
  'access',
  'accessibility',
  'add-circle',
  'add-hotel',
  'add-square',
  'add',
  'adventure-stays',
  'airport',
  'alert-circle',
  'alert-triangle',
  'all-inclusive',
  'amazon',
  'app',
  'arrow-down-circle',
  'arrow-down-square',
  'arrow-down',
  'arrow-left-circle',
  'arrow-left-square',
  'arrow-left',
  'arrow-right-circle',
  'arrow-right-square',
  'arrow-right',
  'arrow-small-right',
  'arrow-up-circle',
  'arrow-up-square',
  'arrow-up',
  'arrowhead-down-circle',
  'arrowhead-down-square',
  'arrowhead-down',
  'arrowhead-left-circle',
  'arrowhead-left-square',
  'arrowhead-left',
  'arrowhead-right-circle',
  'arrowhead-right-square',
  'arrowhead-right',
  'arrowhead-small-down',
  'arrowhead-small-left',
  'arrowhead-small-right',
  'arrowhead-small-up',
  'arrowhead-up-circle',
  'arrowhead-up-square',
  'arrowhead-up',
  'audio-description',
  'audit-log',
  'auto-upgrade',
  'av',
  'award-selected',
  'award',
  'bag',
  'bagel',
  'balcony',
  'banner',
  'bath',
  'bathrobe',
  'beach',
  'bed',
  'bell',
  'bin',
  'boutique-hotels',
  'breakfast',
  'building-of-interest',
  'bullet',
  'business-centre',
  'buy-points',
  'calendar-search',
  'calendar',
  'cancellation',
  'car-rental',
  'care',
  'casino',
  'champagne',
  'check-circle',
  'check-in',
  'check-out',
  'check-square',
  'check-uncontained',
  'check',
  'checkbox-unselected',
  'checkmark-heavy',
  'checkmark-shining',
  'choose-your-room',
  'christmas-tree',
  'circle-filled',
  'city',
  'cleaning-equipment',
  'cleaning',
  'clear',
  'clock',
  'close-circle',
  'close-square',
  'close',
  'closed-captions',
  'cocktails',
  'coffee',
  'cold-breakfast',
  'compass',
  'concierge',
  'connecting-rooms',
  'contact',
  'convert-points',
  'cookie',
  'copy',
  'cosmetics',
  'credit-card',
  'credit-cards',
  'currency',
  'diamond',
  'digital-checkout',
  'digital-key',
  'dining',
  'download',
  'edit',
  'ellipses',
  'enlarge',
  'entertainment',
  'epay',
  'ev-charging',
  'evening-reception',
  'event',
  'ewallet',
  'exercise-bike',
  'face-covering',
  'facebook',
  'family',
  'fast-forward',
  'favorite-hotel',
  'favorite',
  'filters',
  'find-hotel',
  'firearms',
  'fireplace',
  'flag',
  'flower',
  'food-&-beverage',
  'free-nights',
  'full-kitchen',
  'gallery',
  'garden-view',
  'gift',
  'gluten-free',
  'gold-bar',
  'golf-cart',
  'golf-clubs',
  'golf-instructions',
  'golf-practice',
  'golf',
  'grain-free',
  'group',
  'guarantee-policy',
  'gym',
  'hairdryer',
  'halal',
  'handle',
  'happy',
  'help-circle',
  'help-square',
  'help',
  'hide',
  'home',
  'hot-breakfast',
  'hot-tub',
  'hotel-location',
  'hotel-selected',
  'hotel',
  'house',
  'image',
  'in-room-kitchen',
  'indoor-pool',
  'induction-cooktop',
  'info-circle',
  'instagram',
  'inventory',
  'iron',
  'key-dispenser',
  'keys',
  'kid-friendly',
  'kids-club',
  'kitchen',
  'kitchenette-and-fridge',
  'kosher',
  'language',
  'lift',
  'link',
  'living-space',
  'location',
  'lock',
  'lounge',
  'luggage',
  'luxury',
  'lyft',
  'mail',
  'map-location',
  'map-pin',
  'massage-treatments',
  'meeting',
  'megaphone',
  'menu',
  'michelin-star',
  'microwave',
  'mini-fridge-and-microwave',
  'mini-fridge',
  'minibar',
  'mobile-app',
  'mobile',
  'mouse',
  'movies',
  'music',
  'nearby-hotels',
  'new-hotel',
  'new-window',
  'night',
  'nights-circle',
  'no-image',
  'no-pets',
  'no-smoking',
  'ocean-view',
  'optional-services',
  'order-ahead',
  'organic',
  'other-functions',
  'outdoor-pool',
  'paid-upgrade',
  'parking',
  'pause-circle',
  'pause',
  'peleton-amenities',
  'person',
  'pet-friendly',
  'pinterest',
  'play-circle',
  'play',
  'point-of-interest',
  'points-and-money',
  'points-promotions',
  'points',
  'policies',
  'pool-points',
  'pool',
  'price',
  'print',
  'prodigy-home',
  'prodigy-portal',
  'pyjamas',
  'qr-pay',
  'radio-button-selected',
  'radio-button-unselected',
  'rate',
  'raw',
  'refresh',
  'remote',
  'remove-circle',
  'remove-square',
  'remove',
  'renovated-hotel',
  'residence',
  'resort',
  'restart',
  'restaurant',
  'review',
  'rewards',
  'rewind',
  'rideshare',
  'rollaway-bed',
  'rollaway-crib',
  'rollover-nights',
  'room-guarantee',
  'room-plan',
  'room-service',
  'room-type-setting',
  'room-upgrade',
  'safe',
  'sailing',
  'sale',
  'search',
  'settings',
  'share',
  'shopping',
  'shower',
  'shuttle-service',
  'ski',
  'skip-back',
  'skip-forward',
  'slippers',
  'smoking',
  'snacks',
  'snorkel',
  'sound-off',
  'sound-on',
  'spa',
  'special-rate-plan',
  'special-requests',
  'spin',
  'spray-bottle',
  'stadium',
  'star-burst',
  'star-circle-filled',
  'star-circle',
  'star-half',
  'star',
  'streaming-tv',
  'suitcase',
  'suites',
  'support',
  'surfboard',
  'taxes',
  'telephone',
  'tennis',
  'terrace',
  'theme-park',
  'tiktok',
  'towels',
  'transcripts',
  'transfer',
  'translation',
  'trumpet',
  'turndown-service',
  'tv',
  'unavailable',
  'unhappy',
  'unhide',
  'unlock',
  'user-circle',
  'user-info',
  'user-square',
  'user',
  'users',
  'vegan',
  'vegetarian',
  'wallet',
  'water-bottle',
  'waterpark',
  'wedding-bird',
  'wedding-cake',
  'wedding',
  'whatsapp',
  'wifi',
  'x',
  'yoga',
  'youtube',
] as const;

export const IconSetNames = new Set([...IconNames]);
